<template>
  <section>
    <title-section
      title="Mensajes"
    />
    <messages-main />
  </section>
</template>

<script>
export default {
  name: 'Messages',
  components: {
    TitleSection: () => import('@/components/UI/TitleSection.vue'),
    MessagesMain: () => import('@/components/Messages/MessagesMain.vue')
  }
}
</script>

<style lang="scss" scoped>

</style>
